import React from 'react'

function Footer (){
    return(
        <footer className = ' flex justify-center pt-[30px] absolute bottom-0 w-full h-[80px] bg-[#212121]'>
            <h1 className = "text-white">© 2022 Copyright: <span className = 'underline' ><a href = "/">Aarin Jasikumar</a></span></h1>
        </footer>
    )



}

export default Footer;