import {React, useEffect} from "react";
import Aos from 'aos'
import 'aos/dist/aos.css'

function Title(props){
    useEffect(() => {
        Aos.init({duration: 2000})
    },[]);

    return(
        <div  data-aos='fade-right' className="flex justify-start">
            <div className = 'pt-[120px] min-w-min w-0 pb-[50px]'>
                <p className = 'text-5xl font-bold border-b-4 border-blue-400'>{props.title.charAt(0)}
                <span className = 'text-4xl'>{props.title.substring(1,)}</span>
                </p>
            </div>
        </div>
    )


}

export default Title;