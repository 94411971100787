import React, { useEffect } from 'react'
import Card from './Card';
import Title from './Title';

import CSS from '../assets/css.png'
import HTML from '../assets/html.png'
import PYTHON from '../assets/python.png'
import DATABASE from '../assets/database.png'
import JAVA from '../assets/java.png'
import REACT from '../assets/react.png'
import TAILWIND from '../assets/tailwind.png'
import JS from '../assets/js.png'
import Eclipse from '../assets/eclipse.png'
import Android from '../assets/android.png'
import VS from '../assets/vs.png'
import Github from '../assets/github.png'
import Logo from '../assets/logo_color_blue.png'

import Rotation from '../assets/3d.png'
import Wordle from '../assets/wordle.png'
import Library from '../assets/library.png'
import Calendar from '../assets/calendar.png'
import Video from '../assets/v.png'

import Onboarders from '../assets/t.png'

import Aos from 'aos'
import 'aos/dist/aos.css'

function Artifacts(props){

    useEffect(() => {
        document.title = 'Projects | Aarin Jasikumar';
    }, []);

    useEffect(() => {
        Aos.init({duration: 2000})
    },[]);

    return(
        <div className = 'w-full h-full bg-[#212121] text-gray-300 min-h-[900px]'>
            <div className = 'max-w-[1200px] mx-auto px-8 h-full pb-[100px]'> 
            <Title title={props.title.toUpperCase()}/>
                <div data-aos='fade-left' className = 'flex flex-wrap justify-center gap-8'>
                    
                <Card 
                        image = {Onboarders}
                        title = 'Remote Desktop Setup and Onboarding Documentation'
                        text = {`
                        Date: June 3rd 2024
                        Produced For: Dayforce Remote Desktop Onboarders
                        
                        Context:
                        As one of the first interns to be onboarded with a remote desktop setup, I was responsible for setting up my work environment and documenting any issues encountered during the process. This task was part of a new initiative within the company, and the goal was to ensure that future new hires could set up their environments more efficiently.
                       
                        Development Details:
                        The process began with setting up my machine, which involved extensive communication with the IT team and managers to troubleshoot new issues that arose due to the remote desktop setup. Over three weeks, I encountered and resolved multiple errors that were previously undocumented. I then created detailed documentation of these errors and their solutions, which would serve as a reference for future users.
                        
                        Outcome:
                        The documentation provided a valuable resource for future new hires, enabling them to navigate the setup process more smoothly. My contributions helped establish a more efficient onboarding process for remote desktop users.
                        
                        Reflection:
                        Through this experience, I learned how to troubleshoot and resolve complex technical issues independently. It also improved my confidence in reaching out to various departments for assistance. If I were to do this again, I would ensure that my documentation is even more detailed, to further simplify the onboarding process for newcomers.
                        `}
                        tags={[]}
                        gitrepo = 'https://github.com/Aarin06/CSCB07_Project'
                        now = ''
                        b1 = {false}
                        b2 = {false}
  
                   
                    />
                     <Card 
                        image = {Calendar}
                        title = 'Union-Class Validation Framework'
                        text = {`
                          Date: July 24th 2024
                          Produced For: Dayforce Position Classification Microservice

                          Context:
                          The goal of this artifact was to develop a functionality within our software to validate whether a union could be correctly added to a specific class, as part of a story in our sprint. The feature was developed to enhance the ability of employers to associate different unions with various job categories. My role involved researching existing validation frameworks within our codebase to understand prior implementations, while also consulting with co-workers for help.
                          
                          Development Details:
                          To develop the validation framework by utilizing our existing codebase and resources. I implemented the framework by creating a separate interface and class, which allows for easy reuse in future projects. This approach was suggested by my peers and proved beneficial in maintaining code modularity and scalability. The skills I applied during this process included critical thinking, written communication, and programming.
                          
                          Outcome:
                          The result was a functional framework that tested and validated union-class associations, so when a user attempted to associate a union with a class, the system would provide either a success message or a detailed error message. The feedback I received included suggestions to add more comprehensive tests, particularly negative tests, to ensure the code's robustness.

                          Reflection:
                          Through this project, I learned the importance of writing tests before developing the feature, as it helps identify necessary cases and ensures the code meets all requirements. If I were to undertake a similar task in the future, I would start with test-driven development (TDD) to improve the overall quality and efficiency of the coding process. This experience allowed me to further develop my skills in software validation, modular design, and collaboration.
                          `}
                        tags={[]}
                        gitrepo = 'https://github.com/Aarin06/CSCB07_Project'
                        now = ''
                        b1 = {false}
                        b2 = {false}
  
                   
                    />
                    <Card 
                        image = {Video}
                        title = 'Video Demo of Union Feature for HR Team'
                        text={`
                        Date: August 1st 2024
                        Produced For: Public Sector HR Team

                        Context:
                        The goal of this task was to create a concise and informative video demo explaining a new union feature to the HR team. The feature allows users to associate unions with specific job classes, providing success messages or detailed error messages when a union cannot be added. Given that the audience consisted of non-technical HR professionals, I had to ensure the explanation was clear and accessible.
                        
                        Development Details:
                        To prepare, I first familiarized myself with the feature by testing its functionality, especially since part of the code was not written by me. I then reviewed previous demo videos to understand the pacing and level of detail that was effective in past presentations. Using this information, I created a video demo just under 5 minutes long that clearly explained how the feature works and its practical use cases.
                        
                        Outcome:
                        The demo successfully conveyed the functionality of the union feature to the HR team, enhancing their understanding of how it can be utilized in production. I received positive feedback on the clarity and thoroughness of the presentation.
                        
                        Reflection:
                        This experience highlighted the challenge of communicating technical information to a non-technical audience. It improved my communication skills, particularly in simplifying complex concepts. If I were to do this again, I would focus more on the practical use cases rather than the implementation details, as this is more valuable for the audience.
                        `}
                        tags={[]}
                        gitrepo = 'https://github.com/Aarin06/CSCB07_Project'
                        now = ''
                        b1 = {false}
                        b2 = {false}
  
                   
                    />
                    

                    
                    
                </div>

            </div> 
            
        </div>

    )



}

export default Artifacts;