import React, { useEffect, useRef,useState } from 'react'
import Title from './Title';
import Aos from 'aos'
import 'aos/dist/aos.css'
import emailjs from '@emailjs/browser';

function Contact(props){

    const form = useRef();
    const [result, showResult] = useState(false);
    const sendEmail = (e) => {
        
        e.preventDefault();

        emailjs.sendForm('gmail', 'template_5vp8pga', form.current, 'DyOm52ERa6fOQMCFK')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
        showResult(true);

    };
    
    useEffect(() => {
        Aos.init({duration: 2000})
    },[]);

    useEffect(() => {
        document.title = 'Contact | Aarin Jasikumar';
    }, []);

    return(
        <div className = 'w-full h-full bg-[#212121] text-gray-300 min-h-[900px]'>
            <div className = 'max-w-[1200px] mx-auto px-8 h-full pb-[100px]'> 
                <Title title={props.title.toUpperCase()}/>
                <h1 data-aos='fade-left' className = "text-xl sm:text-3xl mb-6">Feel free to contact me through email with the following form.</h1>
                <form ref={form} onSubmit={sendEmail} data-aos='fade-left' className = 'shadow-lg shadow-[#000000] flex flex-col gap-1 font-medium text-gray-100 w-[400px ] mt-[0px] p-4 '>
                    <label className = 'text-xl pb-1'>Name</label>
                    <input name = 'fromName'className = 'outline-blue-400 rounded mb-6 p-2 text-lg text-black'/>
                    
                    <label className = 'text-xl pb-1'>Email Address</label>
                    <input name = 'email' className = 'outline-blue-400 rounded mb-6 p-2 text-lg text-black'/>

                    <label className = 'text-xl pb-1' >Message</label>
                    <textarea name = 'message'rows = '3' className = 'outline-blue-400 rounded mb-3 p-2 text-lg text-black'/>
                    
                    <div>{
                    result ? <input disabled className = "mb-0 w-full bg-blue-400/80 px-6 py-2 text-white" type='submit' value='Message Sent'/> : <input className = "mb-0 w-full bg-blue-400 hover:bg-blue-400/60 px-6 py-2 text-white" type='submit' value='Send'/>
                      
                    }</div>

                    
                    
                </form>
            </div> 
            
        </div>
    )



}

export default Contact;