import React from 'react'


function Tags(props){
    return(
     
        <div className = "flex flex-row space-x-2 px-3 py-1 rounded-full text-gray-300 bg-[#000000] align-middle">
            <img className = "h-[18px]" src = {props.logo}></img>
            <p className = "text-sm font-medium"> {props.name} </p>
        </div>
      
    )

}

export default Tags;