import React, { useEffect } from 'react';
import Aos from 'aos'
import 'aos/dist/aos.css'
import Title from './Title';
import Portrait from '../assets/normal.JPG'

function About(props){
    useEffect(() => {
        document.title = 'About | Aarin Jasikumar';
    }, []);
    
    
    useEffect(() => {
        Aos.init({duration: 2000})
    },[]);

      return(
        <div className = 'w-full h-full bg-[#212121] text-gray-300 min-h-[900px]'>
            <div className = 'max-w-[1200px] mx-auto px-8 h-full pb-[100px]'>
                <Title title={props.title.toUpperCase()}/>


                

                <div className='flex flex-col lg:flex-row place-items-start gap-10'>
                    <img
                        src={Portrait}
                        alt="Aarin Jasikumar"
                        className="rounded-lg max-h-96 object-cover mb-6 lg:mb-0 lg:max-h-96 lg:max-w-[30%] mx-auto "
                        data-aos="fade-right"
                    />
                    <div data-aos='fade-left' className='flex flex-col px-6 lg:px-10 w-full lg:w-[70%]'>
                        <p className='text-2xl md:text-4xl font-bold w-full'>Hi, I’m Aarin. I'm excited to explore the world of computer science, where my passion for problem solving is ignited through technology.</p>
                        <p className='py-4 text-base md:text-lg'>I'm currently a fourth-year student studying at the University of Toronto specializing in the field of Software Engineering within Computer Science.</p>
                        <p className='text-base md:text-lg'>Software development is a tool that allows me to build and create solutions through the use of technology. As I venture further into my degree, I have learned to harness my skills to create anything I want, like my very own Pickleball League. I've also grown during my many months of interning at UofT, CCS, and Dayforce. I look forward to enhancing my skills and using them to solve and simplify problems around the world.</p>
                    </div>
                </div>

        
            </div> 
            
        </div>
    )



}

export default About;