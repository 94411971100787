import React, { useEffect } from 'react'
import Tags from './Tag';
import Aos from 'aos'
import 'aos/dist/aos.css'


function WorkCard(props){
   
    useEffect(() => {
        Aos.init({duration: 2000})
    },[]);

    return(
        //change max with back to 350px
        <div className = ' flex flex-row border-4 border-black text-gray-300 max-w-full min-h-[10rem] mt-[0px] pb-4 shadow-lg shadow-black pt-4'>
            
            <div className='px-6 hidden sm:block'>
            <img
                className='max-h-[100px] max-w-[200px] mx-auto rounded-lg hidden lg:block'
                src={props.image}
                alt=''
                />

            </div>
            <div className = 'px-4 h-full w-full'>
                <div className='flex items-center justify-between'>
                    <h2 className="font-bold text-xl mt-2 text-left">
                        {props.positionTitle}
                    </h2>
                    <img
                        className='max-h-[60px] max-w-[200px] block lg:hidden'
                        src={props.image}
                        alt=''
                    />
                </div>
                <h2 className="font-medium text-lg mt-2 text-left">
                    {props.company}
                </h2>
                <h2 className="text-base mt-2 text-left">
                    {props.date}
                </h2>
               
                <ul className='list-outside list-disc ml-6 mt-6'> 
                    {props.points.map((point) => {
                        return (
                            <li className='text-sm md:text-base'>{point}</li>
                        )
                    })}
                </ul>
            </div>
            
        </div>
        
    )



}

export default WorkCard;