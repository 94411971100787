import React, { useEffect } from 'react'
import Card from './Card';
import Title from './Title';

import CSS from '../assets/css.png'
import HTML from '../assets/html.png'
import PYTHON from '../assets/python.png'
import DATABASE from '../assets/database.png'
import JAVA from '../assets/java.png'
import REACT from '../assets/react.png'
import TAILWIND from '../assets/tailwind.png'
import JS from '../assets/js.png'
import Eclipse from '../assets/eclipse.png'
import Android from '../assets/android.png'
import VS from '../assets/vs.png'
import Github from '../assets/github.png'
import NorthernPickleball from '../assets/npickleball.png'
import Planpal from '../assets/Planpal.png'
import Rotation from '../assets/3d.png'
import Library from '../assets/library.png'
import Calendar from '../assets/calendar.png'
import Course from '../assets/course.png'
import Nodejs from '../assets/nodejs.png'
import MongoDB from '../assets/mongodb.png'
import Stripe from '../assets/stripe.jpeg'
import Angular from '../assets/angular.png'
import postgreSQL from '../assets/postgre.png'
import Google from '../assets/google.png'
import Pacman from '../assets/pacman.png'
import Firebase from '../assets/firebase.png'

import Aos from 'aos'
import 'aos/dist/aos.css'

function Projects(props){

    useEffect(() => {
        document.title = 'Projects | Aarin Jasikumar';
    }, []);

    useEffect(() => {
        Aos.init({duration: 2000})
    },[]);

    return(
        <div className = 'w-full h-full bg-[#212121] text-gray-300 min-h-[900px]'>
            <div className = 'max-w-[1200px] mx-auto px-8 h-full pb-[100px]'> 
            <Title title={props.title.toUpperCase()}/>
                <div data-aos='fade-left' className = 'flex flex-wrap justify-center gap-8'>
                    
                <Card 
                        image = {NorthernPickleball}
                        title = 'Northern Pickleball'
                        text={'Northern Pickleball is a full-stack website built using the MERN stack, enabling users to register and pay for pickleball leagues and tournaments with Stripe integration and webhooks. The platform streamlines the signup process and features real-time match scoring for an enhanced user experience.'}
                        tags={[{logo:MongoDB, name:"MongoDB"},{logo:REACT, name:"React"},{logo:Nodejs, name:"Node"},{logo:JS, name:"Express"},{logo:Stripe, name:"Stripe"}]}
                        gitrepo = 'https://github.com/Aarin06/TrueNorthPickleball'
                        now = 'https://northernpickleball.ca/'
                        b1 = {true}
                        b2 = {true}
                    />

                    <Card 
                        image = {Planpal}
                        title = 'Planpal'
                        text={'PlanPal is a collaborative travel planning app that enables users to create trips, view events with Google Maps integration, and collaborate in real-time on shared calendars using Socket.IO. With premium features like smart recommendations powered by the Google Places API and Stripe-managed payments, it streamlines trip planning.'}
                        tags={[{logo:Angular, name:"Angular"},{logo:Google, name:"Google"},{logo:Stripe, name:"Stripe"},{logo:postgreSQL, name:"PostgreSQL"},{logo:JS, name:"Javascript"}]}
                        gitrepo = 'https://github.com/Aarin06/planpal'
                        now = 'http://planpal.tech/'
                        b1 = {true}
                        b2 = {true}
                    />
                    
                     <Card 
                        image = {Calendar}
                        title = 'Event Scheduling App'
                        // text = 'This project was created using Android Studio as I led a group of 6 people as the Scrum Master to create an event scheduling application. This project included multiple sprints and daily meetings. This application demonstrates an understanding of Design Patterns and Object Oriented Programming as it uses classes, abstract classes, interfaces, inheritance, and polymorphism. It also uses the firebase real-time database that allows for user authentication and data reading, writing, and creation.'
                        text={"I led a team of six as Scrum Master to develop an event scheduling app using Android Studio, following multiple sprints and daily meetings. The app showcases design patterns and OOP principles like inheritance and polymorphism, while integrating Firebase for real-time user authentication and data management."}
                        tags={[{logo:JAVA, name:"Java"},{logo:Android, name:"Android"},{logo:Firebase, name:"Firebase"}]}
                        gitrepo = 'https://github.com/Aarin06/CSCB07_Project'
                        now = ''
                        b1 = {true}
                        b2 = {false}
                    />
                     <Card 
                        image = {Pacman}
                        title = 'Pacman AI Projects'
                        // text = 'I created this project in high school and it allows users to create an account, sign in, sign out, and take out and return items. However, this application demonstrates an understanding of searching and sorting algorithms such as binary search, insertion sort, and selection sort. It also demonstrates an understanding of recursion, object-oriented programming, classes, inheritance, polymorphism, and reading and writing files.'
                        text={'I developed this project in high school, enabling users to create accounts, sign in, sign out, and manage items. It showcases my understanding of algorithms like binary search and insertion sort, as well as recursion, OOP principles, and file handling.'}
                        tags={[{logo:PYTHON, name:"Python"}]}
                        gitrepo = 'https://github.com/Aarin06/Pacman-Artificial-Intelligence'
                        now = ''  
                        b1 = {true}
                        b2 = {false}
                    />
                    <Card 
                        image = {Rotation}
                        title = '3D Object Transformations'
                        // text = 'This project was created using Java and is an application that can be used to read 3D objects from the file types, OBJ, OFF, and PLY. From here, the methods within the application allow the user to rotate their 3D objects using matrices that represent the degree of rotation as well as the axis of rotation. This application demonstrates an understanding of Object Oriented Programming as it uses classes, abstract classes, interfaces, inheritance, and polymorphism.'
                        text={'This Java application reads 3D objects from OBJ, OFF, and PLY file types and allows users to rotate them using matrix transformations. It demonstrates OOP principles like inheritance and polymorphism through the use of classes, abstract classes, and interfaces.'}
      
                        tags={[{logo:JAVA, name:"Java"},{logo:Eclipse, name:"Eclipse"}]}
                        gitrepo = 'https://github.com/Aarin06/3D-Rotation-App'
                        now = ''
                        b1 = {true}
                        b2 = {false}
             
                    />
                    
                   
                    
                    <Card 
                        image = {Course}
                        title = 'Full Stack Course Website'
                        // text = 'For the final project in my Introduction to Web Development and Databases course, I created a full-stack course website. This website used basic HTML and CSS for the front end and Python Flask for the back end. The website allows includes user authentication and password encryption using Bcrypt as well. There are also different account types with different permissions. As a student, you may be able to review your current marks and assignments, request regrades, and send anonymous feedback. As a professor or TA, you may mark assignments, assign work and read feedback.'
                        text={'For my final project, I built a full-stack course website using HTML, CSS, and Python Flask. It features Bcrypt-encrypted authentication and role-based permissions, allowing students to view grades and submit feedback, while professors and TAs manage assignments.'}
                        tags={[{logo:HTML, name:"HTML"},{logo:CSS, name:"CSS"},{logo:JS, name:"Javascript"},{logo:PYTHON, name:"Python"},{logo:DATABASE, name:"SQL"}]}
                        gitrepo = 'https://github.com/Aarin06/CSCB20-A3'
                        now = ''
                        b1 = {true}
                        b2 = {false}
                    />
    
                    {/* <Card 
                        image = {Logo}
                        title = 'This Website!'
                        // text = 'I have created this website from scratch using React and Tailwind CSS. I decided to make the project to learn React and showcase my understanding of React and its many features such as components, props, etc. I was able to finish this project within 3 days because of my background in HTML and CSS. Now, I am currently working on another project that uses React with Node JS so I can create a full-stack website with these technologies!'
                        text={''}
                        tags={[{logo:HTML, name:"HTML"},{logo:JS, name:"Javascript"},{logo:REACT, name:"React"},{logo:TAILWIND, name:"Tailwind"},{logo:VS, name:"VS Code"},{logo:CSS, name:"CSS"}]}
                        now = '/'
                        b1 = {false}
                        b2 = {true}

                    />
                    <Card 
                        image = {Wordle}
                        title = 'Wordle'
                        text = 'This project is currently under development. It is a clone to the popular New York Times Game, Wordle. '
                        tags={[{logo:HTML, name:"HTML"},{logo:CSS, name:"CSS"},{logo:JS, name:"Javascript"},{logo:REACT, name:"React"},{logo:VS, name:"VS Code"}]}
                        gitrepo = ''
                        now = ''
                        b1 = {false}
                        b2 = {false}
                    /> */}

                    
                    
                </div>

            </div> 
            
        </div>

    )



}

export default Projects;