import React, { useEffect } from 'react'
import WorkCard from './WorkCard';
import Title from './Title';

import Dayforce from '../assets/dayforce.png'
import UofTLogo from '../assets/uoftlogo1.png'

import Aos from 'aos'
import 'aos/dist/aos.css'

function Work(props){

    useEffect(() => {
        document.title = 'Projects | Aarin Jasikumar';
    }, []);

    useEffect(() => {
        Aos.init({duration: 2000})
    },[]);

    return(
        <div className = 'w-full h-full bg-[#212121] text-gray-300 min-h-[900px]'>
            <div className = 'max-w-[1300px] mx-auto px-8 h-full pb-[100px]'> 
            <Title title={props.title.toUpperCase()}/>
                <div data-aos='fade-left' className = 'flex flex-col justify-center gap-8'>
                    
                <WorkCard 
                image = {Dayforce}
                    company = 'Dayforce'
                    positionTitle = 'Full-Stack Software Developer'
                    date={"May 2024 - Present"}
                    points={[
                        "Engineered a validation framework for a microservice using C#, GraphQL, and .NET, improving data integrity by 30% through precise input validation and clear detailed error messages.",
                        "Revamped data seeding and cleaning scripts through SSMS, increasing testing efforts and minimizing downtime by 25%.",
                        "Collaborated with cross-functional teams using an agile test-driven development style to complete features on both the frontend and backend, resulting in a 20% reduction in bug stories.",
                        "Implemented a full-stack Minimum Balances feature using C#, .NET, GraphQL, and React, enabling 20,000+ government employees to set and manage minimum balance thresholds for CBAs, reducing manual tracking by 40%.",
                        "Designed and implemented an external RESTful Web API within a monolithic architecture to expose data services for seamless integration with microservices, ensuring efficient data flow and compatibility across systems."
                      ]}                    
                />
                 <WorkCard 
                image = {UofTLogo}
                    company = 'UofT & CCS'
                    positionTitle = 'Full-Stack Lead Software Developer'
                    date={"May 2023 - Dec 2023"}
                    points={[
                        "Developed a secure, user-friendly data transfer system using React, Node.js (REST API’s), and Shell Scripts, enabling file transfers between servers, boosting team efficiency by 200% through eliminating the reliance on terminal commands.",
                        "Integrated Gogs into the Docker network to automate data versioning within the platform, increasing data security by 30% and clearing over 20GB of space, through storing data in deltas rather than as separate files.",
                        "Onboarded a team of three developers in 2 hours and created comprehensive Cypress work items to implement user testing across the entire platform, maximizing developer productivity and minimizing production errors.",
                        "Actively engaged in stakeholder meetings by presenting significant platform enhancements and skillfully navigating discussions to address security concerns while achieving client requests, fostering satisfaction and responsiveness.",
                        "Revamped firewall rules utilizing UFW and ufw-docker, enabling automated HTTPS certificate renewal to restrict platform access solely through a secure VPN, enhancing both system resilience and data protection."
                      ]}                    
                />

               
                    
                </div>

            </div> 
            
        </div>

    )



}

export default Work;